<template>
  <Modal sol-modal>
    <a href="/images/H_CORE_Solution_Proposal_Sample.zip" class="noti" download>
      <img src="/images/mo/solution2.png" alt="">
    </a>
    <a class="close" @click="$emit('resolve')">닫기</a>
  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
export default {
  name: "SolutionDetailModal",
  components: {Modal},
  data() {
    return {
    }
  },
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[sol-modal] {
  .inner { .t(120); .h(60vh)!important; .scroll; transform: translate(-50%,0); .w(90%);
    .noti { .wf; .ib; .pointer;
      img { .wf; }
    }
    .close { .wh(50,50)!important; .abs; .z(10); .bg('/images/pc/x.svg'); .no-repeat(); .cover(); top: revert; }
  }
}

@media screen and(min-width:1024px) {
  [sol-modal] { .fix;
    .inner { .mt(0)!important; .t(50%); .wh(1125,90vh)!important; .max-h(1000); transform: translate(-50%,-50%);
      .noti { .wf; }
      .close { .wh(50,50)!important; }
    }
  }
}
</style>