<template>
  <div main :class="{ready}">
    <Paragraph>
      <article>
        <div class="vk-wrap" :style="{height: this.mainKvH}" :data-swiper="realIndex">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(slide, idx) in slideList" :key="'slide'+slide" :class="`slide`+(idx+1)">
                <div class="vk-img"></div>
              </div>
            </div>
          </div>
          <div class="kv-wrap">
            <div class="kv-cont" v-for="(kv, idx) in kvList" :key="'kv'+kv.id" :class="'kv'+(idx+1)">
              <p class="vk-title">
                <span v-for="(ti, tdx) in kv.titArray" :key="ti" v-html="ti" :class="'ti'+(tdx+1)"></span>
              </p>
              <RouterLink :to="kv.link" class="more">{{ kv.more }} <i></i><s></s></RouterLink>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <div class="cont1">
          <h3 class="para"><b>기본을 지키는</b> 프리미엄 강재,<br class="mo"> <b>안</b>전을 <b>심</b>은 철</h3>
          <p class="tit para">안심 <i></i></p>
          <ul>
            <li class="para" v-for="(steel, idx) in steelList" :key="'steel'+steel.id" :class="'steel'+(idx+1)">
              <RouterLink :to="steel.link"></RouterLink>
              <div class="list-img"></div>
              <p class="nm" v-html="steel.nm"></p>
              <p class="txt web" v-html="steel.txt"></p>
              <p class="more-url"><s>more</s></p>
            </li>
          </ul>
        </div>
        <div class="cont2">
          <div class="inner">
            <p class="tit para">당신의 비즈니스 성공을<br class="mo"> 지원합니다<i>!</i></p>
            <p class="sub-tit para"><i class="vk-logo"></i> 에 대해 궁금하신 점을<br class="mo"> 최고의 전문가와 상담하세요</p>
            <RouterLink to="/support/qna" class="dir-btn para">Q & A 바로가기 <i></i></RouterLink>

            <h3 class="para"><i class="vk-logo"></i>의 새로운 소식을<br class="mo"> 만나보세요</h3>
            <News :news="news"/>
            <RouterLink to="/story/news" class="dir-btn news-btn para"><b>H CORE</b> NEWS 바로가기 <i></i></RouterLink>
          </div>
        </div>

        <div class="dir-wrap" :class="{floor:isOn}">
          <a href="https://www.hyundai-steel.com/kr/index.hds" target="_blank" class="dir-st">steel</a>
          <a href="https://hcorestore.hyundai-steel.com" target="_blank" class="dir-e">e-commerce</a>
          <a target="_blank" class="dir-home" @click="fnAeService()">AE service</a>
        </div>
      </article>
    </Paragraph>

    <div class="sol-pop" v-if="solPop">
      <img src="/images/mo/solution1.png" alt="">
      <a class="detail-pop" @click="solDetailPopOpen();">자세히 보기</a>
      <a class="sol-close" @click="solPopChkDayClose();">닫기</a>
      <div class="sol-pop-footer">
        <input type="checkbox" id="chkDay">
        <label for="chkDay">오늘 하루동안 보지 않기</label>
      </div>
    </div>

  </div>
</template>

<script>
import Paragraph from "@/views/components/Paragraph";
import Swiper from "swiper/swiper-bundle.min";
import News from "@/views/components/News";
import axios from 'axios'
import emarketModal from "@/views/components/modal/EmarketModal";
import solutionDetailModal from "@/views/components/modal/SolutionDetailModal";


export default {
  name: "Main",
  components: {News, Paragraph},
  data() {
    return {
      ready: true,
      slideList: 5,
      kvList: [
        { id:'1', titArray: ['<b>안</b>전을 <b>심</b>은 철', '<b>안심</b> <i class="vk-logo"></i>'], more: 'About H CORE', link:'/brand/about' },
        { id:'2', titArray: ['<b>현대제철</b>의 <s class="web">뛰어난 기술력과</s>', '<s class="mo"> 뛰어난 기술력과</s>', '차별화된 경쟁력을 갖춘','<i class="vk-logo"></i>'], more: 'All Product', link:'/product' },
        { id:'3', titArray: ['<b>“유연하지 않으면 안 됩니다”</b>', '외부의 충격에 유연하게 대처하는<br class="mo"> <i class="vk-logo"></i>'], more: 'Brand Video', link:'/story' },
        { id:'4', titArray: ['<b>“끈질기지 않으면 안 됩니다”</b>', '극한의 환경에는 끈질기게 이겨내는<br class="mo"> <i class="vk-logo"></i>'], more: 'Brand Video', link:'/story' },
        { id:'5', titArray: ['<b>“튼튼하지 않으면 안 됩니다”</b>', '오랜 세월에도<br class="mo"> 튼튼하게 지탱하는 <i class="vk-logo"></i>'], more: 'Brand Video', link:'/story' },
      ],
      steelList: [
        {nm: 'SECTION', moreUrl:'', txt: '강도의 신뢰성과<br> 충격 흡수능력이 뛰어나<br> 구조물의 주요 구조재료로<br> 널리 사용됩니다', link: '/product#prod1'},
        {nm: 'REINFORCING<br>BAR', moreUrl:'', txt: 'D10~D57의 폭넓은 규격과<br> 고강도, 용접용, 내진용, 극저온용 등<br> 다양한 강종이 제공됩니다', link: '/product#prod2'},
        {nm: 'PLATE', moreUrl:'', txt: '6mm 이상의 두꺼운 철강재로<br> 저온, 고온, 부식 등<br> 외부 환경에 잘 버텨냅니다', link: '/product#prod3'},
        {nm: 'STEEL PIPE', moreUrl:'', txt: '다양한 규격(직경, 두께, 재질)과<br> 뛰어난 품질로 극한의 하중을<br> 견뎌냅니다', link: '/product#prod4'},
        {nm: 'HR/CR', moreUrl:'', txt: '어떠한 환경과 복잡한 가공에도<br> 품질과 성능을 발휘하는<br> 철강제품의 꽃 입니다', link: '/product#prod5'}
      ],
      news: [],
      pageH: '',
      isOn: false,
      mainKvH: '100vh',
      realIndex: 0,
      solPop: false,
    }
  },
  async asyncData({ host }) {
    const { data } = await axios.get(host + '/api/board/news', { params: { perPage: 4, _: +new Date() } });
    return { news: data.list };
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.ready = false;
      this.init();
    },500);
    this.winHeight();
    this.mainKvHeight();
    // 10월 29일까지만 나오도록, 오늘 하루 보지 않기.
    const targetDate = new Date('2024-10-29');
    const currentDate = new Date();
    console.log("targetDate : " + targetDate);
    console.log("currentDate : " + currentDate);
    const isBeforeTargetDate = currentDate < targetDate;
    let chkDay = this.getChkDay(); //true --> 팝업창 안나옴, false --> 팝업창 나옴
    if(isBeforeTargetDate && !chkDay){
        this.solPop = true;
    }else{
        this.solPop = false;
    }
  },
  methods: {
    init() {
      new Swiper('.swiper', {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        loop:true,
        speed: 2000,

        /*autoplay: {
          delay: 3000,
          disableOnInteraction:false,
          autoplayDisableOnInteraction: false,
        },*/

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        on: {
          slideChange: swiper => {
            this.realIndex = swiper.realIndex
          }
        },
      });
    },
    handleScroll() {
      if(this.pageH <= (document.body.scrollTop || document.documentElement.scrollTop + window.innerHeight)+200) {
        this.isOn = true;
      }
      if(this.pageH > (document.body.scrollTop || document.documentElement.scrollTop + window.innerHeight)+200) {
        this.isOn = false;
      }
    },
    winHeight() {
      setTimeout(() => this.pageH = document.documentElement.scrollHeight, 500)
    },
    emarketModal() {
      this.$modal(emarketModal);
    },
    mainKvHeight() {
      if(window.innerWidth < 768) {
        this.mainKvH = window.innerHeight + 'px';
      } else if(window.innerWidth > 1024) {
        this.mainKvH = (window.innerHeight + 37) + 'px';
      } else if(window.innerWidth > 1400) {
        this.mainKvH = (window.innerHeight + 22) + 'px';
      }
    },
    handleResize() {
      this.mainKvHeight();
    },
    solDetailPopOpen() {
      this.solPop = false;
      this.$modal(solutionDetailModal);
    },
    solPopChkDayClose(){
      this.solPop = false;
      const chkDay = document.getElementById('chkDay');
      if(chkDay.checked){
        this.setChkDay();
      }else {
        localStorage.removeItem("chkDay");
      }
    },
    setChkDay(){
      let date = new Date();
      date = date.setTime(date.getTime() + 24*60*60*1000);
      localStorage.setItem("chkDay", date);
    },
    // true --> 안나옴, false --> 나옴
    getChkDay(){
      let now = new Date();
      now = now.setTime(now.getTime());
      console.log("getChkDay : " + (parseInt(localStorage.getItem('chkDay')) > now));
      return parseInt(localStorage.getItem('chkDay')) > now;
    },
    fnAeService (){
      location.href = "/index";
    }
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[main] {
  .motion-s { .o(0); transform: translateY(50px); }
  .motion-e { transform: translateY(0); .o(1); transition: transform 0.7s, opacity 0.7s; .ease-out; }
  article { .rel;
    .vk-wrap { .h(100vh); .rel; overflow: hidden;
      .swiper { .f; .fix; .lt;
        .swiper-slide { .rel; overflow: hidden;
          .vk-img { .f; object-fit: cover; transition: transform 1.5s; transition-delay: 2.4s; .ease-out; }
          &.slide1 .vk-img { background: url('/images/mo/main-vk1.jpg')no-repeat center center; .cover; }
          &.slide2 .vk-img { background: url('/images/mo/main-vk2.jpg')no-repeat center center; .cover; }
          &.slide3 .vk-img { background: url('/images/mo/main-vk3.jpg')no-repeat center center; .cover; }
          &.slide4 .vk-img { background: url('/images/mo/main-vk4.jpg')no-repeat center center; .cover; }
          &.slide5 .vk-img { background: url('/images/mo/main-vk5.jpg')no-repeat center center; .cover; }
        }
      }
      .kv-wrap { .wh(100%,500); .abs; .lt(70,50%); transform: translateY(-50%); pointer-events: none;
        .kv-cont { .abs; .lt; .tl;
          .vk-title { pointer-events: none; .rel;
            &:after { .cnt; .wh(960,360); .bgc(#fff); .abs; .lt(0,50%); transform: translateY(-50%); z-index: -1; .o(0); transition: opacity 0.7s; }
            span { .fs(60); .lh(72); color:#333; .light; .ggo; .block; .motion-s; .pl(40); .-box; }
            .vk-logo { .wh(267,53); .contain('/images/mo/main1-logo.png'); .ib; .vam; .mt(-10); }
            b { .bold; }
          }
          .more { .fs(24); color:#fff; .robo; .bold;.bgc(#73dee8); .wh(350,60); .lh(60); .pl(25); .-box; .ib; .tc; .abs; .lt(0,50%); .mt(210); z-index: 1; .motion-s;
            i { .wh(50,15); .contain('/images/mo/ico-more.png'); .ib; .m(10,0,12,25); .vam; }
            s { .f; .abs; .lt; .bgc(#73dee8); color:#fff; z-index: -1; transition: background-color 0.5s; }
            &:hover s { .bgc(#153347); }
          }
          &.kv2 {
            .vk-title span .vk-logo { .mt(10); }
            .more { .bgc(#008cb9);
              s { .bgc(#008cb9); }
              &:hover s { .bgc(#153347); }
            }
          }
          &.kv3, &.kv4, &.kv5 {
            .vk-title { .rel;
              &:after { .cnt; .bgc(#000); }
              span { .fs(40); .lh(48); .c(#fff);
                &.ti1 { .fs(50); .lh(72); .pl(18); }
                .vk-logo { .wh(178,35); .contain('/images/mo/logo-w.png'); .mt(0); }
              }
            }
            .more { .bgc(#014579);
              s { .bgc(#014579); }
              &:hover s { .bgc(#153347); }
            }
          }
        }
      }
      .swiper-pagination { .fs(0); .abs; .lb(70,60); .tl; width:auto; .motion-e; transition-delay: 0.8s;
        &:after { .cnt; .wh(92,92); .contain('/images/mo/ico-per.png'); .abs; .lt(50%,50%); transform: translate(-50%,-50%); }
        span { .fs(60); .lh(60); .roboc; .thin; color:#fff; .rel; .block;
          &:before { content:'0' }
          &.swiper-pagination-current { .bold; }
          &.swiper-pagination-total { .ml(45); }
        }
      }
    }
    [data-swiper='0'] .kv-wrap .kv1,
    [data-swiper='1'] .kv-wrap .kv2,
    [data-swiper='2'] .kv-wrap .kv3,
    [data-swiper='3'] .kv-wrap .kv4,
    [data-swiper='4'] .kv-wrap .kv5 { .ib; z-index: 1; }
    [data-swiper='0'] .kv-wrap .kv1 .vk-title:after,
    [data-swiper='1'] .kv-wrap .kv2 .vk-title:after,
    [data-swiper='2'] .kv-wrap .kv3 .vk-title:after,
    [data-swiper='3'] .kv-wrap .kv4 .vk-title:after,
    [data-swiper='4'] .kv-wrap .kv5 .vk-title:after { .o(0.3)!important; }
    [data-swiper='0'] .kv-wrap .kv1 .vk-title span,
    [data-swiper='1'] .kv-wrap .kv2 .vk-title span,
    [data-swiper='2'] .kv-wrap .kv3 .vk-title span,
    [data-swiper='3'] .kv-wrap .kv4 .vk-title span,
    [data-swiper='4'] .kv-wrap .kv5 .vk-title span { .motion-e;
      &.ti2 { transition-delay: 0.2s }
      &.ti3 { transition-delay: 0.4s }
      &.ti4 { transition-delay: 0.6s }
    }
    [data-swiper='0'] .kv-wrap .kv1 .more,
    [data-swiper='1'] .kv-wrap .kv2 .more,
    [data-swiper='2'] .kv-wrap .kv3 .more,
    [data-swiper='3'] .kv-wrap .kv4 .more,
    [data-swiper='4'] .kv-wrap .kv5 .more { pointer-events: auto; .motion-e; transition-delay: 0.8s; }

    //[data-swiper='0'] .kv-wrap {
    //  .kv0 .vk-title, .kv0 .more { .motion-e; }
    //}
    //[data-swiper='1'] .kv-wrap {
    //  .kv1 .vk-title, .kv1 .more { .motion-e; }
    //}
    //[data-swiper='2'] .kv-wrap {
    //  .kv2 .vk-title, .kv2 .more { .motion-e; }
    //}


    .cont1 { .bgc(#f2f2f2); .p(140,25,125); .tc; .rel;
      h3 { .fs(48); .lh(60); .light; .ggo;
        b { .bold; }
      }
      .tit { .fs(76); .bold; .mt(10); .ggo;
        i { .wh(266,57); .ib; .contain('/images/mo/cont-logo.png'); .vam; .mt(-10); }
      }
      ul { .mt(60);
        li { .wh(100%,250); .bgc(#fff); .mb(2); .rel;
          a { .f; .ib; z-index: 1; .rel; .pointer; }
          .list-img { .wh(700,250); .abs; .t(0); }
          .nm { .fs(40); .roboc; .bold; .lh(40); .tl; .abs; .t(50); .w(290); }
          .more-url { .w(100); .tl; .abs; .rb(0,25);
            s { .fs(20); .lh(36); .roboc; .o(0.6); }
            &:after { .cnt; .wh(80,14); .contain('/images/mo/main-list-arrow.png'); .abs; .lb; .o(1); }
          }
          &.steel1 {
            .list-img { .contain('/images/mo/list1.png'); .l(0); }
            .nm { .r(0); }
          }
          &.steel2 {
            .list-img { .contain('/images/mo/list2.png'); .r(0); }
            .nm { .l(50); }
          }
          &.steel3 {
            .list-img { .contain('/images/mo/list3.png'); .l(0); }
            .nm { .r(0); }
          }
          &.steel4 {
            .list-img { .contain('/images/mo/list4.png'); .r(0); }
            .nm { .l(50); }
          }
          &.steel5 {
            .list-img { .contain('/images/mo/list5.png'); .l(0); }
            .nm { .r(0); .pl(120); .-box; }
          }
        }
      }
    }
    .cont2 { .tc; .p(95,50,180); .-box; .ggo; .bgc(#fff); .rel;
      &:after { .cnt; .wh(100%,500); .abs; .lb(50%,0); .bgc(#f2f2f2); transform: translateX(-50%); }
      .inner { z-index: 1; .rel;
        .tit { .fs(56); .bold; .mb(30);
          i { font-style: italic; }
        }
        .sub-tit { .fs(36); .lh(48); .light; .mb(35);
          .vk-logo { .wh(151,30); .contain('/images/mo/logo-blue2.png'); .ib; .vam; .mt(-5); }
        }
        .dir-btn { .fs(24); .spoqa; .lh(60); .ib; .tc; .w(400); .rel; .-a(#333); .mt(5); .pl(65); .-box; .bgc(#fff); transition: background-color 0.3s, transform 0.5s, opacity 0.5s; .ease-out;
          b { .bold; }
          i {  .wh(77,58); .contain('/images/mo/ico-dir-arrow.png'); .ib; .vam; .ml(15); }
          &:nth-of-type(2) { .pl(25); }
          &:hover { .bgc(#153347); color:#fff;
            i { filter: invert(1); }
          }
          &.news-btn { .mt(70); }
        }
        h3 { .fs(48); .lh(60); .tc; .light; .pt(135); .-box; .m(100,0,65); .-t(#333); color:#0d1112;
          .vk-logo { .wh(207,42); .contain('/images/mo/logo-blue.png'); .ib; .vam; .mt(-5); }
        }
      }
    }
    .dir-wrap { .fs(0); .fix; .rb(0,10); z-index: 15; transition: margin-bottom 0.5s; transition-delay: 0.5s;
      a { .wh(179,75); .block; .mr(-2); box-shadow: 5px 4px 0 0 rgba(0,0,0,0.1);
        &.dir-st { .contain('/images/mo/dir1.png'); }
        &.dir-e { .contain('/images/mo/dir2.png'); .mt(10); }
        &.dir-home { .contain('/images/mo/dir3.png'); .mt(10); }
      }
      &.floor { .mb(400); }
    }
  }
  &.ready article .vk-wrap .swiper-pagination { .motion-s; }

  .sol-pop { .fs(0); .tc; .abs; .lt(50%,150); .w(80%); .z(100); transform: translateX(-50%);
    img { .wf; }
    .detail-pop { .wf; .abs; .lt; .z(9); height: calc(100% - 1.5625rem);}
    .sol-close { .wh(50,50); .abs; .rt; .z(10); .bg('/images/pc/x.svg'); .no-repeat(); .cover(); right: revert;}
    .sol-pop-footer{.wf; .h(50); .bgc(#fff);
      input{.wh(30, 30); .z(10); .l(0); .rel(); .-a(#000); .t(-10); .mr(10);}
      label{ .fs(30); .c(#000); .z(10); .pointer;}
    }
  }
}
@media screen and(min-width:1024px) {
  [main] {
    .motion-s { .o(0); transform: translateY(100px); }
    .motion-e { transform: translateY(0); .o(1); transition: transform 1s, opacity 1s; .ease-out; }
    article {
      .vk-wrap {
        .swiper {
          .swiper-slide {
            &.slide1 .vk-img { background: url('/images/pc/main-vk1.jpg')no-repeat center center; .cover; }
            &.slide2 .vk-img { background: url('/images/pc/main-vk2.jpg')no-repeat center center; .cover; }
            &.slide3 .vk-img { background: url('/images/pc/main-vk3.jpg')no-repeat center center; .cover; }
            &.slide4 .vk-img { background: url('/images/pc/main-vk4.jpg')no-repeat center center; .cover; }
            &.slide5 .vk-img { background: url('/images/pc/main-vk5.jpg')no-repeat center center; .cover; }
          }
        }
        .kv-wrap { .wh(100%,380); .abs; .lt(50%,50%); transform: translate(-50%,-50%); .mt(10); .tc;
          .kv-cont { .wh(1230,100%); .lt(50%,0); .ml(-615); .tc;
            .vk-title { .fs(72); .lh(90); .nowrap;
              &:after { .cnt; .wh(940,360); .lt(50%,50%); transform: translate(-50%,-50%); transition: opacity 1s; }
              span { .fs(72); .lh(90); .motion-s; }
              .vk-logo { .wh(302,60); .contain('/images/pc/logo-blue2.png'); .vam; }
            }
            .more { .fs(24); .wh(350,60); .lh(60); .lt(50%,50%); .m(190,0,0,-175);
              &:hover { .bgc(#153347); }
            }
            &.kv2 {
              .vk-title span .vk-logo { .mt(10); }
            }
            &.kv3, &.kv4, &.kv5 {
              .vk-title {
                span { .fs(40); .lh(72); .pl(0);
                  &.ti1 { .fs(72); .lh(90); .pl(0); }
                  .vk-logo { .wh(178,35); .contain('/images/mo/logo-w.png'); .mt(0); }
                }
              }
            }
          }
        }
        .swiper-pagination { .lt(50,50%); .motion-e; transition-delay: 0.8s; .mt(-150);
          &:after { .cnt; .wh(92,92); .contain('/images/mo/ico-per.png'); .abs; .lt(5,50%); transform: translateY(-50%); }
        }
      }
      [data-swiper='0'] .kv-wrap .kv1,
      [data-swiper='1'] .kv-wrap .kv2,
      [data-swiper='2'] .kv-wrap .kv3,
      [data-swiper='3'] .kv-wrap .kv4,
      [data-swiper='4'] .kv-wrap .kv5 { .ib; z-index: 1; }
      [data-swiper='2'] .kv-wrap .kv3 .vk-title:after,
      [data-swiper='3'] .kv-wrap .kv4 .vk-title:after,
      [data-swiper='4'] .kv-wrap .kv5 .vk-title:after { .o(0.3)!important; }
      [data-swiper='0'] .kv-wrap .kv1 .vk-title span,
      [data-swiper='1'] .kv-wrap .kv2 .vk-title span,
      [data-swiper='2'] .kv-wrap .kv3 .vk-title span,
      [data-swiper='3'] .kv-wrap .kv4 .vk-title span,
      [data-swiper='4'] .kv-wrap .kv5 .vk-title span { .motion-e;
        &.ti2 { transition-delay: 0.2s }
        &.ti3 { transition-delay: 0.4s }
        &.ti4 { transition-delay: 0.6s }
      }
      [data-swiper='0'] .kv-wrap .kv1 .more,
      [data-swiper='1'] .kv-wrap .kv2 .more,
      [data-swiper='2'] .kv-wrap .kv3 .more,
      [data-swiper='3'] .kv-wrap .kv4 .more,
      [data-swiper='4'] .kv-wrap .kv5 .more { pointer-events: auto; .motion-e; transition-delay: 0.8s; }


      .cont1 { .p(140,0,95);
        h3 {  .lh(72); }
        .tit { .fs(76); }
        ul { .mh-c; .mt(80); .wf;
          li { .max-w(344); .wh(20%,600); .mb(0); .ib; .vat; overflow: hidden;
            .list-img { .f; transition: all 0.5s; .ease-out; }
            .nm { .fs(36); .lt(40,50)!important; width:auto; .p(0)!important; }
            .txt { .fs(18); .lh(24); .ls(-0.05em); .spoqa; .abs; .lb(46,64); .tl; .o(0); transition: opacity 0.5s; .ease-out; }
            .more-url { width:auto; .lb(48,40);
              &:after { transition: all 0.3s; }
              s { .fs(18); }
            }
            &.steel1 .list-img { .cover('/images/pc/list1.png'); }
            &.steel2 .list-img { .cover('/images/pc/list2.png'); }
            &.steel3 .list-img { .cover('/images/pc/list3.png'); }
            &.steel4 .list-img { .cover('/images/pc/list4.png'); }
            &.steel5 .list-img { .cover('/images/pc/list5.png'); }
          }
          li:hover {
            .list-img { transform: scale(1.1); .o(0.5); }
            .txt { .o(1); }
            .more-url {
              &:after { .cnt; .wh(50,14); .contain('/images/pc/main-list-arrow-hover.png'); }
              s { .o(0); }
            }
          }
        }
      }
      .cont2 { .p(140,0); .rel;
        &:after { .cnt; .wh(100%,400); .abs; .lb(50%,0); .bgc(#f2f2f2); transform: translateX(-50%); }
        .inner { .w(1400); .mh-c; .rel; z-index: 1;
          .tit { .fs(48); .mb(20); }
          .sub-tit { .fs(36); .mb(0); }
          .dir-btn { .fs(18); .lh(50); .wh(300,50); .mt(60);
            i { .m(-5,0,0,20); }
            &:nth-of-type(2) { .abs; .rt(0,460); .mt(0); .pl(25);
              i { .m(-5,-10,0,0); }
            }
          }
          h3 { .m(90,0,90); .tl; }
        }
      }
      .dir-wrap { .rt(0,50%); transform: translateY(-40%);
        a { .wh(180,75); box-shadow: 10px 8px 0 0 rgba(0,0,0,0.1); transition: background 0.3s;
          &.dir-st { .contain('/images/pc/dir1.png'); }
          &.dir-e { .contain('/images/pc/dir2.png'); .mt(8); }
          &.dir-home { .contain('/images/pc/dir3.png'); .mt(8); }
          &:hover {
            &.dir-st { .contain('/images/pc/dir1-hover.png'); }
            &.dir-e { .contain('/images/pc/dir2-hover.png'); }
            &.dir-home { .contain('/images/pc/dir3-hover.png'); }
          }
        }
        &.floor { .mb(0); }
      }
    }
    &.ready article .vk-wrap {
      .swiper-pagination, .sd { .motion-s; }
    }
    .sol-pop { .lt(100,200); .w(800); transform: translateX(0);
      .detail-pop {height: calc(100% - 0.9375rem);}
      .sol-close { .wh(50,50); }
      .sol-pop-footer{.h(30);
        input{.wh(20, 20); .t(-7);}
        label{ .fs(20); .z(10);}
      }
    }
  }
}
@media screen and(min-width:1400px) {
  [main] {
    article {
      .vk-wrap {
        .swiper-pagination { .l(50%); .ml(-860); width:auto; }
      }
    }
  }
}
</style>
